<!--*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===* -->

<template>
  <div id="data-list">
    <vx-card
      ref="filterCard"
      title="Adding new FAQ"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
     <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <div class="vx-row">
        
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>QUESTION
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="question"
                    rules="required|min:1"
                  >
                    <vs-textarea
                      class="w-full"
                      v-model="question"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                      height="200px"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>ANSWER
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="answer"
                    rules="required|min:1"
                  >
                    <vs-textarea
                      class="w-full"
                      v-model="answer"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                      height="200px"
                    />
                  </validation-provider>
                </div>
        </div>

        <vs-button
          :disabled="invalid"
          type="filled"
          @click="addFaq()"
          class="mb-2"
          >SUBMIT</vs-button
        >
      </form>
     </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});

export default {
   components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      question: null,
      answer: null
    };
  },
  methods: {
    addFaq() {
      this.$vs.loading();

      axios
        .post(`${process.env.VUE_APP_BASE_URI}/api/admin/faqs`, {
           question : this.question, answer: this.answer
        })
        .then(response => {
          this.$vs.loading.close();
          if (response.status === 200) {
            this.clearData();
            this.$vs.notify({
              title: "Success",
              text: "FAQ is added",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
             this.$router.push("../GetAll/FAQ");
          } else {
            this.$vs.notify({
              title: "Error",
              text: "FAQ is not added",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    },
    goToFaqList() {
     this.$router.push("/GetAll/FAQ");
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.city_name = null;
    },
    clearData() {
      this.city_name = null;
    }
  }
};
</script>
